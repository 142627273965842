import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { alpha, useTheme } from "@mui/material/styles";
import Container from "components/Container";

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const LeftSide = () => (
    <Box data-aos={isMd ? "fade-right" : "fade-up"}>
      <Box marginBottom={2}>
        <Typography
          color={"primary"}
          component={"span"}
          variant="h3"
          fontWeight={700}
          sx={{
            background: `linear-gradient(180deg, transparent 82%, ${alpha(theme.palette.secondary.main, 0.3)} 0%)`,
          }}
        >
          build stellar quizzes about anything
        </Typography>
        <Typography variant="h3" color={theme.palette.secondary.main} sx={{ fontWeight: 700 }}>
          on the fly
        </Typography>
      </Box>
      <Box marginBottom={3}>
        <Typography variant="h4" component="p" color="text.grey">
          master. don't memorize.
        </Typography>
      </Box>
    </Box>
  );

  const RightSide = () => {
    return (
      <Box
        sx={{
          height: { xs: "auto", md: 1 },
        }}
      >
        <Box height={{ xs: "auto", md: 1 }} maxHeight={{ xs: 300, md: 1 }} width={1} maxWidth={1} />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/MYHkQ6lSPW4?si=ZnwfXISyIkLL2TiQ"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: "hidden",
        padding: 2,
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
        <Box display={"flex"} flexDirection={{ xs: "column", md: "row" }} position={"relative"}>
          <Box width={1} order={{ xs: 2, md: 1 }}>
            <Container>
              <LeftSide />
            </Container>
          </Box>
          <Box width={1} order={{ xs: 2, md: 1 }}>
            <Container>
              <RightSide />
            </Container>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
